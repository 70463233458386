// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mv-js": () => import("./../../../src/pages/mv.js" /* webpackChunkName: "component---src-pages-mv-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-tech-capstone-js": () => import("./../../../src/pages/tech/capstone.js" /* webpackChunkName: "component---src-pages-tech-capstone-js" */),
  "component---src-pages-tech-carboncrediting-js": () => import("./../../../src/pages/tech/carboncrediting.js" /* webpackChunkName: "component---src-pages-tech-carboncrediting-js" */),
  "component---src-pages-tech-drones-js": () => import("./../../../src/pages/tech/drones.js" /* webpackChunkName: "component---src-pages-tech-drones-js" */),
  "component---src-pages-tech-fibre-js": () => import("./../../../src/pages/tech/fibre.js" /* webpackChunkName: "component---src-pages-tech-fibre-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-tech-software-js": () => import("./../../../src/pages/tech/software.js" /* webpackChunkName: "component---src-pages-tech-software-js" */),
  "component---src-pages-tech-vertistacks-js": () => import("./../../../src/pages/tech/vertistacks.js" /* webpackChunkName: "component---src-pages-tech-vertistacks-js" */)
}

